import s from 'styled-components';

export const Page = s.div`
  // padding-bottom: 100px;
  // height: 1800px;
  // background-color: ${props => props.theme.secondaryBackground};
`;

export const Title = s.h2`
  margin-bottom: 30px;
`;

export const ContentBlock = s.article`
  // background-color: pink;
`;
