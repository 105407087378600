import EditForm from './EditForm';

const Edit = () => {
  return (
    <div>
      <h1>Edit article</h1>
      <EditForm />
    </div>
  );
};

export default Edit;
