import AddForm from './AddForm';

const Add = () => {
  return (
    <div>
      <h1>Add article</h1>
      <AddForm />
    </div>
  );
};

export default Add;
