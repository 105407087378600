import UpdateAdminForm from './UpdateAdminForm';

const LogIn = () => {
  return (
    <>
      <h1>Log in</h1>
      <UpdateAdminForm />
    </>
  );
};

export default LogIn;
