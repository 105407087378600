const SuccessDeleteArticle = ({ id }: any) => {
  return (
    <>
      <p>Success</p>
      <p>{`Article ${id} was deleted`}</p>
    </>
  );
};

export default SuccessDeleteArticle;
